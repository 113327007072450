import { ethers } from "ethers";
import { Interface, FormatTypes } from "@ethersproject/abi";
import { default as erc721abi } from "./erc721-abi.json";

const KITSU_CONTRACT_ADDRESS = "0x32569ca9dbe368819e8e726f6887e55e2cd98808";
const kitsuABI = new Interface(erc721abi).format(FormatTypes.full);
const RPC_PROVIDER = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_KITSU_JSON_RPC_NODE);
const KitsuContractReader = new ethers.Contract(KITSU_CONTRACT_ADDRESS, kitsuABI, RPC_PROVIDER);

const PUBLIC_MINT_CONST = 2000
const WHITELIST_MINT_CONST = 2000

// EIP712 TypeHash Signature
export const whitelistMint = async (mintAmount, signature) => {
    if (!window.ethereum) throw "No Injected Web3 Provider Found";
    const ethereum = window.ethereum;
    const accounts = await ethereum.request({
      method: "eth_requestAccounts",
    });

    const provider = new ethers.providers.Web3Provider(ethereum);
    const walletAddress = accounts[0];
    const signer = provider.getSigner(walletAddress);
    console.log(walletAddress);

    const kitsuContract = new ethers.Contract(KITSU_CONTRACT_ADDRESS, kitsuABI, signer);
    const whitelistMint = await kitsuContract.populateTransaction.whitelistMint(mintAmount, signature);
    whitelistMint.value = (WHITELIST_MINT_CONST * mintAmount ).toString();
    whitelistMint.chainId = process.env.REACT_APP_KITSU_NFT_CHAIN_ID;
    whitelistMint.gasLimit = 150000;
    
    const whitelistMintTx = await signer.sendTransaction(whitelistMint).catch((e) => {
        throw e.error.data.data.message;
    });
    const mintReceipt = await whitelistMintTx.wait();
    if (mintReceipt.status === 0)
        throw new Error("Approve transaction failed");

    return {
        txHash: whitelistMintTx.hash,
        receiptStatus: mintReceipt.status
    }
};

export const publicMint = async (mintAmount) => {
    if (!window.ethereum) throw "No Injected Web3 Provider Found";
    const ethereum = window.ethereum;
    const accounts = await ethereum.request({
      method: "eth_requestAccounts",
    });

    const provider = new ethers.providers.Web3Provider(ethereum);
    const walletAddress = accounts[0];
    const signer = provider.getSigner(walletAddress);
    console.log(walletAddress);

    const kitsuContract = new ethers.Contract(KITSU_CONTRACT_ADDRESS, kitsuABI, signer);
    const publicMint = await kitsuContract.populateTransaction.mint(mintAmount);
    publicMint.value = (PUBLIC_MINT_CONST * mintAmount ).toString();
    publicMint.chainId = process.env.REACT_APP_KITSU_NFT_CHAIN_ID;
    publicMint.gasLimit = 150000;
    
    const publicMintTx = await signer.sendTransaction(publicMint).catch((e) => {
        throw e.error.data.data.message;
    });
    const mintReceipt = await publicMintTx.wait();
    if (mintReceipt.status === 0)
        throw new Error("Approve transaction failed");

    return {
        txHash: publicMintTx.hash,
        receiptStatus: mintReceipt.status
    }
};

export const getMintStage = async () => {
    // Stages:
    // 0 => Not Started
    // 1 => Allowlist Mint
    // 2 => Public Mint
    return (await KitsuContractReader.mintConfig()).mintStage;
}

export const getMintPrice = async (stage) => {
    if (stage == 0) return -1;
    const mintConfig = await KitsuContractReader.mintConfig();
    const priceWei = stage == 1 ? mintConfig.GuaranteedListCost : mintConfig.PublicMintCost;
    return ethers.utils.formatEther(priceWei);
}

// mintedAmount / maxSupply => i.e: 2000 / 5555

export const getMintedAmount = async () => {
    return (await KitsuContractReader.totalSupply()).toNumber();
}

export const getMaxSupply = async () => {
    return (await KitsuContractReader.maxSupply()).toNumber();
}

export const isPaused = async () => {
    return await KitsuContractReader.paused();
}

export const getClaimedAmount = async (stage, address) => {
    return (await KitsuContractReader.getClaimedAmount(stage, address)).toNumber();
}