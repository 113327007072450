
import ImgLogo from "../assets/images/logo.webp";
import ImgDivider from "../assets/images/divider/ecosystem-bottom.webp";
import { Link } from "react-router-dom";
import { Toaster } from "react-hot-toast";

function LayoutAuth({title, children}) {
  return (
    <div className="bg-brown min-h-screen py-20">
      <img src={ImgDivider} alt="Divider" className="w-full absolute inset-x-0 bottom-0" />
      <div className="flex justify-center">
        <div className="flex-1 max-w-md">
          <Link to="/">
            <img src={ImgLogo} alt="Logo" className="w-16 mx-auto mb-4" />
          </Link>
          <h1 className="text-white font-barlow font-bold text-center text-3xl md:text-4xl mb-8">{title}</h1>
          <div className="w-full bg-primary p-4 md:p-6 rounded-md">{children}</div>
        </div>
      </div>
      <Toaster />
    </div>
  );
}

export default LayoutAuth;
