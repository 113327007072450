import { Icon } from "@iconify/react";
import { useState } from "react";
import { Link } from "react-router-dom";

function Navbar() {
  const [open, setOpen] = useState(false);

  return (
    <nav className="w-full bg-primary py-4">
      <div className="container px-4 flex justify-between items-center">
        <div className="flex items-center space-x-7 text-brown">
          <a href="https://discord.gg/thekitsu" className="inline-block hover:contrast-50" target="_blank" rel="noreferrer">
            <Icon icon="fa6-brands:discord" className="iconify w-7 h-7" />
          </a>
          <a href="http://twitter.com/thekitsugame" className="inline-block hover:contrast-50" target="_blank" rel="noreferrer">
            <Icon icon="fa6-brands:twitter" className="iconify w-7 h-7" />
          </a>
          <a href="https://www.youtube.com/channel/UC6JTjrtL7boMP1mmgVOQ9HQ" className="inline-block hover:contrast-50" target="_blank" rel="noreferrer">
            <Icon icon="fa6-brands:youtube" className="iconify w-7 h-7" />
          </a>
          {/* <a href="http://instagram.com/thekitsugame" className="inline-block hover:contrast-50" target="_blank" rel="noreferrer">
            <Icon icon="fa6-brands:instagram" className="iconify w-7 h-7" />
          </a>
          <a href="mailto:business@kitsu.one" className="inline-block hover:contrast-50" target="_blank" rel="noreferrer">
            <Icon icon="fa6-regular:envelope" className="iconify w-7 h-7" />
          </a> */}
        </div>

        <div
          className={`items-center font-barlow font-medium uppercase text-2xl lg:text-xl space-y-3 text-brown transform transition-all absolute z-30 inset-x-0 top-20 left-2 right-2 p-6 rounded-xl shadow-2xl bg-primary text-center lg:relative lg:flex lg:space-x-8 lg:top-0 lg:shadow-none lg:p-0 lg:space-y-0 ${
            !open ? "-translate-y-[40rem] lg:translate-y-0" : ""
          }`}
        >
          <a href="/#story" className="block lg:inline-block hover:underline hover:contrast-50">
            Story
          </a>
          <a href="/#about" className="block lg:inline-block hover:underline hover:contrast-50">
            About
          </a>
          <a href="/#roadmap" className="block lg:inline-block hover:underline hover:contrast-50">
            Roadmap
          </a>
          {/* <a href="/#paper" className="block lg:inline-block hover:underline hover:contrast-50">
            Whitepaper
          </a> */}
          <a href="/#nft-metric" className="block lg:inline-block hover:underline hover:contrast-50">
            NFT Metric
          </a>
          <a href="/#team" className="block lg:inline-block hover:underline hover:contrast-50">
            Teams
          </a>
          <a href="/#games" className="block lg:inline-block hover:underline hover:contrast-50">
            Games
          </a>
          <a href="/#ecosystem" className="block lg:inline-block hover:underline hover:contrast-50">
            Ecosystems
          </a>
          {/* <Link to="/presale" className="block lg:inline-block hover:underline hover:contrast-50 bg-brown text-white px-4 py-1 rounded-md">
            Presale
          </Link> */}
        </div>

        <button
          onClick={() => setOpen(!open)}
          className="inline-block lg:hidden px-4 py-2 rounded-2xl bg-primary-300 border-3 border-night-900 font-marvin text-sm btn-wave hover:bg-primary-400 hover:scale-105 transition-all mr-1"
        >
          {open ? <Icon icon="heroicons-outline:x" className="h-6 w-6" /> : <Icon icon="heroicons-outline:menu-alt-2" className="h-6 w-6" />}
        </button>
      </div>
    </nav>
  );
}

export default Navbar;
