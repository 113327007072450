import { Toaster } from "react-hot-toast";
import Footer from "../components/Footer";
import Navbar from "../components/nav/Navbar";

function LayoutBase({children}) {
    return (
      <>
        <Navbar />
        <main>{children}</main>
        <Footer />

        <Toaster />
      </>
    );
}

export default LayoutBase;