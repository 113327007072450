import { Route, Routes } from "react-router-dom";
import PresalePage from "./pages/PresalePage";
import LandingPage from "./pages/LandingPage";
import PrivacyPage from "./pages/PrivacyPage";
import TermsPage from "./pages/TermsPage";
import ProfileEditPage from "./pages/profile/ProfileEditPage";
import MintPage from "./pages/MintPage";
import ComingSoonPage from "./pages/ComingSoon";

function App() {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<ComingSoonPage />} />
        <Route exact path="/privacy" element={<PrivacyPage />} />
        <Route exact path="/terms" element={<TermsPage />} />
        <Route exact path="/presale" element={<PresalePage />} />
        <Route exact path="/edit-profile" element={<ProfileEditPage />} />
        <Route exact path="/mint" element={<MintPage />} />
      </Routes>
    </>
  );
}

export default App;
