import Web3 from "web3";
import BigNumber from "bignumber.js";
import { default as abi } from "./erc20-abi.json";

const RECEIPT_RETRIVAL_MAX_RETRIES = 10

export const usePresaleUtil = () => {
    const BN = Web3.utils.BN;
    const web3 = new Web3(process.env[`REACT_APP_BSC_RPC_PROVIDER_${process.env.REACT_APP_BSC_NETWORK_TYPE}`]);
    const web3injected = new Web3(window.ethereum);
    const usdt = new web3.eth.Contract(abi, process.env[`REACT_APP_BEP20_USDT_${process.env.REACT_APP_BSC_NETWORK_TYPE}`]);

    const getNativeBalance = async ({ walletAddress }) => {
        const balance = await web3.eth.getBalance(walletAddress).catch((e) => {
            console.log(e);
        });

        return balance;
    }

    const signPersonalMessage = async (message, walletAddress) => {
        let signature;
        try {
            signature = web3injected.eth.personal.sign(message, walletAddress);
        } catch (err) {
            console.log(err);
        }
        return signature;
    }

    const getUSDTBalance = async ({ walletAddress }) => {
        const balance = await usdt.methods.balanceOf(walletAddress).call();

        return BN(balance).div(BN(10).pow(BN(18))).toString();
    }

    const transferUSDT = async ({ fromWallet, toWallet, amount }) => {
        const _amount = BigNumber(amount).times(BigNumber(10).pow(BigNumber(18)));

        if (window.ethereum.chainId != process.env[`REACT_APP_BSC_CHAIN_ID_${process.env.REACT_APP_BSC_NETWORK_TYPE}`] && window.ethereum.isMetaMask) {
            await window.ethereum.request({ method: 'wallet_switchEthereumChain', params: [{ chainId: Web3.utils.numberToHex(process.env[`REACT_APP_BSC_CHAIN_ID_${process.env.REACT_APP_BSC_NETWORK_TYPE}`]) }] }).catch((e) => {
                console.log("switch error" + e);
            })
        }
        const transaction = usdt.methods.transfer(toWallet, _amount.toString()).encodeABI();
        const rawTransaction = {
            "from": fromWallet,
            "to": process.env[`REACT_APP_BEP20_USDT_${process.env.REACT_APP_BSC_NETWORK_TYPE}`],
            "data": transaction,
            "chainID": process.env[`REACT_APP_BSC_CHAIN_ID_${process.env.REACT_APP_BSC_NETWORK_TYPE}`],
            "gas": "0xd379"
        };
        const result = await window.ethereum.request({ method: "eth_sendTransaction", params: [rawTransaction] }).catch((e) => {
            console.log(e);
        });
        console.log(await web3.eth.getTransaction(result));

        // Wait Receipt
        let currRetriesCount = 0;
        let receipt = null;
        await new Promise((resolve, reject) => {
            if (result) {
                const interval = setInterval(function () {
                    console.log("Attempting to get transaction receipt...");
                    web3.eth.getTransactionReceipt(result, function (err, rec) {
                        if (rec) {
                            console.log(rec);
                            receipt = rec;
                            clearInterval(interval);
                            resolve();
                        }
                        currRetriesCount++;
                        if (currRetriesCount >= RECEIPT_RETRIVAL_MAX_RETRIES) reject("Error: Transaction Failed");
                    });
                }, 1000);
            } else {
                throw "Error: Invalid Transaction"
            }
        });

        if (!receipt || receipt.status == false) throw "Error: Transaction Failed";
        
        console.log(result);
        return result;
    }

    return {
        getNativeBalance,
        getUSDTBalance,
        transferUSDT,
        signPersonalMessage
    };
};