import axios from "axios";
import axiosRetry, { isNetworkOrIdempotentRequestError }  from "axios-retry";

const internalAPIClientAbortController = new AbortController();

const internalAPIClient = axios.create({
  baseURL: process.env.REACT_APP_KITSU_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "x-auth-kitsu": localStorage.getItem("x-auth-kitsu") || "",
  },
  signal: internalAPIClientAbortController.signal,
});

internalAPIClient.interceptors.response.use(
  (resp) => {
    if (resp?.headers?.["x-auth-kitsu"] || resp?.data?.data?.kitsu_token) {
      localStorage.setItem("x-auth-kitsu", resp?.headers?.["x-auth-kitsu"] ?? resp?.data?.data?.kitsu_token);
      localStorage.setItem("x-user-kitsu", JSON.stringify(resp?.data?.data));
    }
    return resp;
  },
  (err) => {
    if (err.response.status === 401 || err.response.status == 403) {
      // localStorage.removeItem("x-auth-kitsu");
      // localStorage.removeItem("x-user-kitsu");

      // Temporary Disabled
      // if (!window.location.href.endsWith("auth")) {
      //   window.location.href(`${process.env.PUBLIC_URL}/auth`);
      // }
    }
  }
);

axiosRetry(internalAPIClient, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: (c) => isNetworkOrIdempotentRequestError(c) || c.response.status === 429,
});

export const useAuthenticatedHttpClient = () => {
  return {
    internalAPIClient,
    internalAPIClientAbortController,
  };
};
