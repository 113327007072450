import { createContext, useEffect, useState } from "react";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(undefined);

  useEffect(() => {
    const checkLoggedIn = async () => {
      let cuser = isAuthenticated();
      if (cuser === null) {
        localStorage.removeItem("x-user-kitsu");
        cuser = null;
      }

      setCurrentUser(cuser);
    };

    checkLoggedIn();
  }, []);

  return <UserContext.Provider value={[currentUser, setCurrentUser]}>{children}</UserContext.Provider>;
};

export const isAuthenticated = () => {
  const user = localStorage.getItem("x-user-kitsu");
  if (user && user !== 'undefined') {
    return JSON.parse(user);
  }

  return null;
};