import LayoutBase from "../layouts/LayoutBase";

function PrivacyPage() {
  return (
    <LayoutBase>
      <section className="bg-brown">
        <div className="max-w-4xl mx-auto px-4 py-20 font-dosis text-white">
          <div className="text-center">
            <h1 className="text-3xl md:text-5xl font-bold mb-2">Privacy Policy</h1>
            <span>Latest update: 11 Aug 2022</span>
          </div>
          <div className="mt-10 space-y-2">
            <p>
              This privacy policy ("Privacy Policy") explains how Kitsu ("Kitsu," "our," "we," or "us") collects, uses, and discloses information about you.
              This Privacy Policy applies when you use www.kitsu.one (the "Website"), contact our customer service team, engage with us on social media, or
              otherwise interact with us.
            </p>
            <p>
              We may change this Privacy Policy from time to time. If we make changes, we will notify you by revising the date at the top of this policy and, in
              some cases, we may provide you with additional notice (such as adding a statement to our Website or sending you a notification). We encourage you
              to review this Privacy Policy regularly to stay informed about our information practices and the choices available to you.
            </p>
          </div>
          <hr className="my-6 opacity-25" />
          <div className="text-lg space-y-6">
            <h2 className="text-2xl md:text-3xl font-bold">COLLECTION OF INFORMATION</h2>
            <div className="space-y-2">
              <h6 className="text-xl font-bold">Information You Provide to Us</h6>
              <p>
                We collect information you provide directly to us. For example, you share information directly with us when you fill out a form, make a
                purchase, communicate with us via third-party platforms, participate in a contest or promotion, request customer support, or otherwise
                communicate with us. The types of personal information we may collect include your name, email address, postal address, phone number, credit
                card and other payment information, and any other information you choose to provide.
              </p>
            </div>
            <div className="space-y-2">
              <h6 className="text-xl font-bold">Information We Collect Automatically When You Interact with Us</h6>
              <p>When you access or use our Website or otherwise transact business with us, we automatically collect certain information, including:</p>
              <ul className="list-disc pl-4 space-y-1">
                <li>
                  <h6 className="font-bold mb-2">Transactional Information:</h6>
                  <p>
                    When you make a purchase or return, we collect information about the transaction, such as product details, purchase price, and the date and
                    location of the transaction.
                  </p>
                </li>
                <li>
                  <h6 className="font-bold mb-2">Device and Usage Information:</h6>
                  <p>
                    We collect information about how you access our Website, including data about the device and network you use, such as your hardware model,
                    operating system version, mobile network, IP address, unique device identifiers, browser type, and app version. We also collect information
                    about your activity on our Website, such as access times, pages viewed, links clicked, and the page you visited before navigating to our
                    Website.
                  </p>
                </li>
                <li>
                  <h6 className="font-bold mb-2">Location Information:</h6>
                  <p>
                    In accordance with your device permissions, we may collect information about the precise location of your device. You may stop the
                    collection of precise location information at any time (see the Your Choices section below for details).
                  </p>
                </li>
                <li>
                  <h6 className="font-bold mb-2">Information Collected by Cookies and Similar Tracking Technologies:</h6>
                  <p>
                    We (and our service providers) use tracking technologies, such as cookies and web beacons, to collect information about you. Cookies are
                    small data files stored on your hard drive or in device memory that help us improve our Website and your experience, see which areas and
                    features of our Website are popular, and count visits. Web beacons (also known as "pixel tags" or "clear GIFs") are electronic images that
                    we use on our Website and in our emails to help deliver cookies, count visits, and understand usage and campaign effectiveness. For more
                    information about cookies and how to disable them, the Your Choices section below.
                  </p>
                </li>
              </ul>
            </div>
            <div className="space-y-2">
              <h6 className="text-xl font-bold">Information We Collect from Other Sources</h6>
              <p>
                We obtain information from third-party sources. For example, we may collect information about you from identity verification services, data
                analytics providers, and mailing list providers (if applicable).
              </p>
            </div>

            <h2 className="text-2xl md:text-3xl font-bold">USE OF INFORMATION</h2>
            <div className="space-y-2">
              <p>We use the information we collect to:</p>
              <ul className="list-disc pl-4 space-y-1">
                <li>Provide, maintain, and improve our products and services;</li>
                <li>
                  Process transactions and send you related information, including confirmations, receipts, invoices, customer experience surveys, and recall
                  notices;
                </li>
                <li>Personalize and improve your experience on our Website;</li>
                <li>Send you technical notices, security alerts, and support and administrative messages;</li>
                <li>Respond to your comments and questions and provide customer service;</li>
                <li>
                  Communicate with you about products, services, and events offered by Kitsu and others and provide news and information that we think will
                  interest you (see the Your Choices section below for information about how to opt out of these communications at any time);
                </li>
                <li>Monitor and analyze trends, usage, and activities in connection with our Website;</li>
                <li>Facilitate contests, sweepstakes, and promotions and process and deliver entries and rewards;</li>
                <li>
                  Detect, investigate, and prevent security incidents and other malicious, deceptive, fraudulent, or illegal activity and protect the rights and
                  property of Kitsu and others;
                </li>
                <li>Debug to identify and repair errors in our Website;</li>
                <li>Comply with our legal and financial obligations; and</li>
                <li>Carry out any other purpose described to you at the time the information was collected.</li>
              </ul>
            </div>

            <h2 className="text-2xl md:text-3xl font-bold">SHARING OF INFORMATION</h2>
            <div className="space-y-2">
              <p>We share personal information in the following circumstances or as otherwise described in this policy:</p>
              <ul className="list-disc pl-4 space-y-1">
                <li>
                  We share personal information with vendors, service providers, and consultants that need access to personal information in order to perform
                  services for us, such as companies that assist us with web hosting, shipping and delivery, payment processing, fraud prevention, customer
                  service, and marketing and advertising.
                </li>
                <li>
                  We may disclose personal information if we believe that disclosure is in accordance with, or required by, any applicable law or legal process,
                  including lawful requests by public authorities to meet national security or law enforcement requirements.
                </li>
                <li>
                  We may share personal information if we believe that your actions are inconsistent with our user agreements or policies, if we believe that
                  you have violated the law, or if we believe it is necessary to protect the rights, property, and safety of Kitsu, our users, the public, or
                  others.
                </li>
                <li>
                  We share personal information with our lawyers and other professional advisors where necessary to obtain advice or otherwise protect and
                  manage our business interests.
                </li>
                <li>
                  We may share personal information in connection with, or during negotiations concerning, any merger, sale of company assets, financing, or
                  acquisition of all or a portion of our business by another company.
                </li>
                <li>We share personal information with your consent or at your direction.</li>
                <li>We also share aggregated or de-identified information that cannot reasonably be used to identify you.</li>
              </ul>
            </div>

            <h2 className="text-2xl md:text-3xl font-bold">SHARING OF INFORMATION</h2>
            <div className="space-y-2">
              <p>
                We allow others to provide analytics services on our behalf across the web and in mobile apps. These entities may use cookies, web beacons,
                device identifiers, and other technologies to collect information about your use of our Website and other websites and applications, including
                your IP address, web browser, mobile network information, pages viewed, time spent on pages or in mobile apps, links clicked, and conversion
                information. This information may be used by Kitsu and others to, among other things, analyze and track data, determine the popularity of
                certain content, and better understand your online activity.
              </p>
            </div>

            <h2 className="text-2xl md:text-3xl font-bold">TRANSFER OF INFORMATION TO THE UNITED STATES AND OTHER COUNTRIES</h2>
            <div className="space-y-2">
              <p>
                Kitsu operates and engages service providers in various jurisdictions. Therefore, we and our service providers may transfer your personal
                information to, or store or access it in, jurisdictions that may not provide levels of data protection that are equivalent to those of your home
                jurisdiction. We will take steps to ensure that your personal information receives an adequate level of protection in the jurisdictions in which
                we process it.
              </p>
            </div>

            <h2 className="text-2xl md:text-3xl font-bold">YOUR CHOICES</h2>
            <div className="space-y-2">
              <h6 className="text-xl font-bold">Cookies</h6>
              <p>
                Most web browsers are set to accept cookies by default. If you prefer, you can usually adjust your browser settings to remove or reject browser
                cookies. Please note that removing or rejecting cookies could affect the availability and functionality of our Website.
              </p>
            </div>
            <div className="space-y-2">
              <h6 className="text-xl font-bold">Communications Preferences</h6>
              <p>You may opt out of receiving newsletters from Kitsu by following the instructions in those communications.</p>
            </div>

            <h2 className="text-2xl md:text-3xl font-bold">ADDITIONAL DISCLOSURES FOR INDIVIDUALS IN EUROPE</h2>
            <div className="space-y-2">
              <p>
                If you are located in the European Economic Area ("EEA"), the United Kingdom, or Switzerland, you have certain rights and protections under the
                law regarding the processing of your personal data, and this section applies to you.
              </p>
            </div>

            <div className="space-y-2">
              <h6 className="text-xl font-bold">Legal Basis for Processing</h6>
              <p>When we process your personal data, we will do so in reliance on the following lawful bases:</p>
              <ul className="list-disc pl-4 space-y-1">
                <li>
                  To perform our responsibilities under our contract with you (e.g., processing payments for and providing the products and services you
                  requested).
                </li>
                <li>
                  When we have a legitimate interest in processing your personal data to operate our business or protect our interests (e.g., to provide,
                  maintain, and improve our products and services, conduct data analytics, and communicate with you).
                </li>
                <li>
                  To comply with our legal obligations (e.g., to maintain a record of your consents and track those who have opted out of communications).
                </li>
                <li>
                  When we have your consent to do so (e.g., when you opt in to receive communications from us). When consent is the legal basis for our
                  processing your personal data, you may withdraw such consent at any time.
                </li>
              </ul>
            </div>

            <div className="space-y-2">
              <h6 className="text-xl font-bold">Data Retention</h6>
              <p>
                We store other personal data for as long as necessary to carry out the purposes for which we originally collected it and for other legitimate
                business purposes, including to meet our legal, regulatory, or other compliance obligations.
              </p>
            </div>

            <div className="space-y-2">
              <h6 className="text-xl font-bold">Data Subject Requests</h6>
              <p>
                Subject to certain limitations, you have the right to request access to the personal data we hold about you and to receive your data in a
                portable format, the right to ask that your personal data be corrected or erased, and the right to object to, or request that we restrict,
                certain processing. If you would like to exercise any of these rights, please contact us at business@kitsu.one.
              </p>
            </div>

            <div className="space-y-2">
              <h6 className="text-xl font-bold">Questions or Complaints</h6>
              <p>
                If you have a concern about our processing of personal data that we are not able to resolve, you have the right to lodge a complaint with the
                Data Protection Authority where you reside. Contact details for your Data Protection Authority can be found using the links below:
              </p>
              <p>
                For individuals in the EEA: <a href="https://edpb.europa.eu/about-edpb/board/members_en">https://edpb.europa.eu/about-edpb/board/members_en</a>
              </p>
              <p>
                For individuals in the UK: <a href="https://ico.org.uk/global/contact-us/">https://ico.org.uk/global/contact-us/</a>
              </p>
              <p>
                For individuals in Switzerland:{" "}
                <a href="https://www.edoeb.admin.ch/edoeb/en/home/the-fdpic/contact.html">https://www.edoeb.admin.ch/edoeb/en/home/the-fdpic/contact.html</a>
              </p>
            </div>

            <h2 className="text-2xl md:text-3xl font-bold">CONTACT US</h2>
            <p>If you have any questions about this Privacy Policy, please contact us at business@kitsu.one.</p>
          </div>
        </div>
      </section>
    </LayoutBase>
  );
}

export default PrivacyPage;