import ImgLogo from "../assets/images/logo.webp";
import ImgBackgroundHeader from "../assets/images/bg/header.webp";
import WhitelistChecker from "../components/whitelist/WhitelistChecker";

export default function ComingSoonPage() {
  return (
    <div className="overflow-hidden bg-black h-screen">
      <div className="glitch mb-8 w-full h-screen">
        <img src="/img/bannerfemale.webp" alt="Banner" className="w-full h-screen object-cover object-right-bottom absolute inset-0" />
        <img src="/img/bannerfemale.webp" alt="Banner" className="w-full h-screen object-cover object-right-bottom absolute inset-0" />
        <img src="/img/bannerfemale.webp" alt="Banner" className="w-full h-screen object-cover object-right-bottom absolute inset-0" />
      </div>
      <div className="absolute inset-0 flex items-center justify-center">
        <div>
          <h1 className="font-barlow text-6xl md:text-8xl font-medium text-white">Coming Soon</h1>
          <WhitelistChecker position="relative" />
        </div>
      </div>
    </div>
  );
}
